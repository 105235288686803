import React from 'react';
import PropTypes from 'prop-types';

function EinsteinSVG ({ className }) {
	return (
		<svg
			className={className}
			version="1.1"
			id="Layer_1"
			xmlns="http://www.w3.org/2000/svg"
			x="0px"
			y="0px"
			width="100px"
			height="100px"
			viewBox="0 0 100 100"
			enableBackground="new 0 0 100 100"
			xmlSpace="preserve"
		>
			<path
				fill="#FFFFFF"
				d="M79.9,53.6c0.3,0.4,0.1,0.9,0,1c-1.6,1.7-2.9,2.8-3.9,3.6c-1.6,1.3-4.5,3.2-5.1,3.5c2,1.3,3,2.4,3.1,3.7
			c-2.1,0.8-3.8,1.3-4.8,1.8c-1.5,0.6-2.5,1.3-4.4,2.4h-0.1v-0.5c0-3.3,0.4-4.7,0-7.4c-0.4-2.7-2.3-6.5-6-7.4
			c-3.6-0.9-8.3,2.6-12.4,0.9s-5.9-1.2-7.6,1S37,59.7,37,61.5v7.4c-1.3-0.9-3-1.7-5.3-2.4c-2-0.6-4.1-1-6.3-1.2
			c-0.5,0-0.8-0.4-0.8-0.9v-0.3c0.6-2.6,1.6-4.4,3-5.5c1.5-1.4,2.5-2.1,3.1-1.9c-1.6-1.9-3-3.3-4.3-4c-1.1-0.6-3.1-1.2-6-1.7
			c-0.4-0.1-0.6-0.5-0.6-1v-0.3c2.1-5.5,4.6-9,7.6-10.5c3.3-1.5,5.6-2.2,7.5-1.9c-0.6-1.5-1.1-2.6-1.4-3.3c-0.3-0.5-0.6-1.3-1.4-2.3
			c-0.3-0.4-0.3-1,0.1-1.3c0.1,0,0.3-0.1,0.4-0.1c1.3-0.3,2.5-0.1,3.9,0.3c1.8,0.5,3.4,1.7,4.9,3.5c-0.1-5.3-0.6-9.7-1.8-13.2
			C39.8,18,52.2,20,55.2,26c2.1,4,3.1,6.7,3.1,8.2c4.1-0.9,7.4-0.5,9.8,1s3.6,2.9,3.8,4.2c-1.9,0.4-3.1,1-3.8,1.9
			c2.3,0.9,6.3,3.3,7.6,5C77.2,48.2,78.6,50.5,79.9,53.6z"
			/>
			<circle fill="#FFFFFF" cx="55" cy="64.6" r="2.5" />
			<circle fill="#FFFFFF" cx="42.5" cy="64.6" r="2.5" />
			<path
				fill="#FFFFFF"
				d="M46.2,70.2c0.9,0.3,1.4,0.5,1.9,0.5c0.8,0,1.8-0.3,3.2-0.8c1.8-0.8,3.6-0.4,4.7,0.8c1.3,1,1.8,3.4,1.3,4.9
			c-0.4,0.9-1,1.8-2,2.4c-0.1,0.1-0.4,0.1-0.5,0.1l-2.2-0.4l-4.2,1.8c-0.1,0.1-0.4,0.1-0.5,0l-3.8-1.8l-1.8,0.4c-0.3,0-0.5,0-0.6-0.3
			c-0.5-0.6-0.9-1-1-1.4C40.4,76,40,75.1,40,73.7s1.3-2.9,2.3-3.4C43.3,69.9,45.1,69.6,46.2,70.2z"
			/>
		</svg>
	);
}

EinsteinSVG.propTypes = {
	className: PropTypes.string
};

export default EinsteinSVG;
