import EmailStudioIcon from '@salesforce/design-system-react/icons/custom/custom105';
import CalendarIcon from '@salesforce/design-system-react/icons/standard/event';
import CampaignIcon from '@salesforce/design-system-react/icons/standard/campaign';
import JourneyBuilderIcon from '@salesforce/design-system-react/icons/custom/custom106';
import MobileStudioIcon from '@salesforce/design-system-react/icons/custom/custom110';
import SocialStudioIcon from '@salesforce/design-system-react/icons/custom/custom112';

const EVENT_TYPE_ICONS = {
	AUTOMATION: {
		icon: JourneyBuilderIcon,
		iconAssistiveText: 'Automation',
		iconAssistiveTextKey: 'homepage.event_types.automation',
		iconCategory: 'custom',
		iconName: 'custom106',
		iconStyle: { backgroundColor: '#b17fd1' }
	},
	CUSTOM: {
		icon: CalendarIcon,
		iconAssistiveText: 'Event',
		iconAssistiveTextKey: 'homepage.event_types.event',
		iconCategory: 'standard',
		iconName: 'event',
		iconStyle: { backgroundColor: '#f59331' }
	},
	CAMPAIGN: {
		icon: CampaignIcon,
		iconAssistiveText: 'Campaign',
		iconAssistiveTextKey: 'homepage.event_types.campaign',
		iconCategory: 'standard',
		iconName: 'campaign',
		iconStyle: { backgroundColor: '#f59331' }
	},
	EMAIL: {
		icon: EmailStudioIcon,
		iconAssistiveText: 'Email',
		iconAssistiveTextKey: 'homepage.event_types.email',
		iconCategory: 'custom',
		iconName: 'custmo105',
		iconStyle: { backgroundColor: '#f0b31d' }
	},
	FBPAGE: {
		icon: SocialStudioIcon,
		iconAssistiveText: 'Facebook Page',
		iconAssistiveTextKey: 'homepage.event_types.facebook_page',
		iconCategory: 'custom',
		iconName: 'custom112',
		iconStyle: { backgroundColor: '#3c8bfc' }
	},
	FBPOST: {
		icon: SocialStudioIcon,
		iconAssistiveText: 'Facebook Post',
		iconAssistiveTextKey: 'homepage.event_types.facebook_post',
		iconCategory: 'custom',
		iconName: 'custom112',
		iconStyle: { backgroundColor: '#3c8bfc' }
	},
	MOBILE: {
		icon: MobileStudioIcon,
		iconAssistiveText: 'Mobile',
		iconAssistiveTextKey: 'homepage.event_types.mobile',
		iconCategory: 'custom',
		iconName: 'custom110',
		iconStyle: { backgroundColor: '#45c79c' }
	},
	PUSH: {
		icon: MobileStudioIcon,
		iconAssistiveText: 'Mobile',
		iconAssistiveTextKey: 'homepage.event_types.mobile_push',
		iconCategory: 'custom',
		iconName: 'custom110',
		iconStyle: { backgroundColor: '#45c79c' }
	},
	TWEET: {
		icon: SocialStudioIcon,
		iconAssistiveText: 'Tweet',
		iconAssistiveTextKey: 'homepage.event_types.tweet',
		iconCategory: 'custom',
		iconName: 'custom112',
		iconStyle: { backgroundColor: '#3c8bfc' }
	},
	GROUPCONNECT: {
		icon: MobileStudioIcon,
		iconAssistiveText: 'Group Connect',
		iconAssistiveTextKey: 'homepage.event_types.group_connect',
		iconCategory: 'custom',
		iconName: 'custom110',
		iconStyle: { backgroundColor: '#45c79c' }
	}
};

export default EVENT_TYPE_ICONS;
